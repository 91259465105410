export const MINIMUM_SEARCH_CHARACTERS = 2

export const WEEKS_IN_YEAR = 52.1429

export const TOTAL_FEE = 0.0025
export const LP_HOLDERS_FEE = 0.0017
export const TREASURY_FEE = 0.000225
export const BUYBACK_FEE = 0.000575

export const PCS_V2_START = 1619136000 // April 23, 2021, 12:00:00 AM
export const ONE_DAY_UNIX = 86400 // 24h * 60m * 60s
export const ONE_HOUR_SECONDS = 3600

export const ITEMS_PER_INFO_TABLE_PAGE = 10

// These tokens are either incorrectly priced or have some other issues that spoil the query data
// None of them present any interest as they have almost 0 daily trade volume
export const TOKEN_BLACKLIST = [
  // These ones are copied from v1 info
  '0xed063c4b769045659ba52f7d0130537ce698911e',
  '0x5e41eff24c8e8203ffc4c0bbcf6f6714272ccd15',
  '0xb3a8938609aaaeb784663580072265cefa44cd20',
  '0x144d3c9318e357646ef11c2bf7b697b4a2790264',
  '0x66860fc8d4c655067bebe87842404f439871eee2',
  '0xa4a188d00a9d2673fd818dcf38a29f8b1fba6d82',
  '0x28452bae357ac220eb9b192d268b405e986a7198',
  '0xb6e0892474c7109040903e5286783e55a255e266',
  '0x82146d6b4347d4aa89c51c1f135d7fdfe41e4552',
  '0xd39b115a37564396e67ec555f6bf727a8934405a',
  '0x584787bc582ac696a84bd6da9662ca88ce5a091f',
  '0x1ffc5a6aa0e768f37f04063626fd567c0ac8e140',
  '0x0ea9ef7bc19dd8a512d1efbc2c327fd04d020410',
  '0x22de74881e6fbb03d8d2343281054450aebc6fc5',
  '0xc3bda54c9298cea7bb51df393ea5f65f36db7484',
  '0x9fb237532f734fd1e4f0747f839b2d167ef45e27',
  '0x847daf9dfdc22d5c61c4a857ec8733ef5950e82e',
  '0x35714bc9351af8653ec9bf0b0720f5d629574816',
  // These ones are newly found
  '0x93ad3c61f5e39c0da07fcebae0261eaa2d3a9314',
  '0x4269e4090ff9dfc99d8846eb0d42e67f01c3ac8b',
  '0xca1f444f8733aec6e17b19ed0650e8f9dfd78e9c',
  '0x94534eeee131840b1c0f61847c572228bdfdde93',
  '0x91975615db28bd173a4d73dbd62813010f839ac3',
]
